<template>
  <div
    class="text-subtitle-1 font-weight-medium d-flex justify-space-between"
    :class="{ 'mt-5 mb-2': !removeMargins }"
    v-bind="$attrs"
  >
    <slot>
      {{ label }}
    </slot>

    <div v-if="hasTitleAppend">
      <form-item v-bind="titleAppend" :prop-path="getPropertyPath(titleAppend)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchemaFormSubtitle',

  components: {
    FormItem: () => import('@/components/schema/FormItem.vue'),
  },

  inheritAttrs: false,

  props: {
    propPath: {
      type: Array,
      default: () => [],
    },

    titleAppend: {
      type: Object,
      default: () => ({}),
    },

    label: {
      type: String,
      default: '',
    },

    removeMargins: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasTitleAppend() {
      return Object.keys(this.titleAppend).length > 0;
    },
  },

  methods: {
    getPropertyPath(item) {
      return item.prop ? [...this.propPath, item.prop] : this.propPath;
    },
  },
};
</script>
