<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menuIsOpen"
      content-class="form-field-date"
      :close-on-content-click="false"
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <!-- Решает проблему, что не открывается календарь при клике на label -->
        <div v-bind="attrs" v-on="on">
          <text-field
            class="form-field-date"
            :value="translatedModel"
            :label="label"
            prepend-inner-icon="mdi-calendar"
            hide-details
            readonly
            v-bind="attrs"
          />
        </div>
      </template>

      <v-date-picker
        v-model="dateModel"
        :type="payload.type"
        :locale="$i18n.locale"
        scrollable
        :title-date-format="titleDateFormat"
        range
        :min="min"
        :max="max"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menuIsOpen = false">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="$refs.menu.save(dateModel)">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
    <div class="calendar-status d-flex mt-0.5 ms-3" :class="{ disabled: !dates?.length }">
      <v-checkbox
        v-for="item in statusList"
        :key="item.value"
        v-model="selectedStatus"
        :label="$t(item.label)"
        :value="item.value"
        multiple
        hide-details
        class="mt-0 me-2"
      />
    </div>
  </div>
</template>

<script>
// node_modules
import { format, addDays, parseISO, compareDesc } from 'date-fns';

// Utils
import { getInheritedListeners } from '@/utils/components';
import { translateDate } from '@/utils/dateFormatting';
import { isEqual } from '@/utils/common';

// Services
import timeService from '@/services/time';

// Components
import TextField from '@/components/TextField.vue';

const DEFAULT_TYPE = 'date';

export default {
  name: 'SchemaFormFieldDate',

  components: { TextField },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    payload: {
      type: Object,
      default: () => ({ type: DEFAULT_TYPE }),
    },
    onlyFutureDate: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: () => ({ text: '', icon: '' }),
    },
  },

  data() {
    return {
      dateModelTest: [],
      menuIsOpen: false,
      dates: [this.value.rentalPeriodAfter, this.value.rentalPeriodBefore],
      currentDate: new Date(),
      selectedStatus: this.value.status,
      statusList: [
        {
          label: 'label.available',
          value: 'available',
        },
        {
          label: 'label.partly_available',
          value: 'partly_available',
        },
        {
          label: 'label.booked',
          value: 'booked',
        },
      ],
    };
  },

  computed: {
    modelWrapper: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },

    min() {
      if (this.payload?.minDate) return this.payload.minDate;
      if (!this.onlyFutureDate) return undefined;

      return format(this.currentDate, 'yyyy-MM-dd');
    },

    max() {
      return this.payload?.maxDate;
    },

    dateModel: {
      get() {
        return this.dates?.slice().sort((a, b) => new Date(a) - new Date(b));
      },

      set(dates) {
        const [date1, date2] = dates;

        this.dates = dates;

        if (date1) {
          this.modelWrapper = {
            ...this.modelWrapper,
            rentalPeriodAfter: date1,
            rentalPeriodBefore: null,
          };
        }

        if (date2) {
          const compareDates = compareDesc(parseISO(date1), parseISO(date2));

          if (compareDates < 0) {
            // Вторая дата идет раньше чем первая
            this.dates = [date2, date1];
            this.modelWrapper = {
              ...this.modelWrapper,
              rentalPeriodAfter: date2,
              rentalPeriodBefore: date1,
            };
            return;
          }

          if (compareDates > 0) {
            // Первая дата идет раньше чем вторая
            this.dates = [date1, date2];
            this.modelWrapper = {
              ...this.modelWrapper,
              rentalPeriodAfter: date1,
              rentalPeriodBefore: date2,
            };
            return;
          }

          // Даты равны
          const nextDate = format(addDays(new Date(date2), 1), 'yyyy-MM-dd');
          this.dates = [date1, nextDate];
          this.modelWrapper = {
            ...this.modelWrapper,
            rentalPeriodAfter: date1,
            rentalPeriodBefore: nextDate,
          };
        }
      },
    },

    statusModel: {
      get() {
        return this.selectedStatus;
      },

      set(value) {
        this.selectedStatus = value;

        this.modelWrapper = {
          ...this.modelWrapper,
          status: this.selectedStatus,
        };
      },
    },

    translatedModel() {
      return this.dateModel
        ?.map(date => {
          return date ? translateDate(this.formatDate(date)) : '';
        })
        .join(' - ');
    },

    inheritedListeners: getInheritedListeners(['input']),
  },

  watch: {
    menuIsOpen(newState) {
      if (!newState && this.dates?.length === 1) {
        const nextDay = format(addDays(new Date(this.dates[0]), 1), 'yyyy-MM-dd');
        this.dates = [this.dates[0], nextDay];
        this.modelWrapper = {
          ...this.modelWrapper,
          rentalPeriodAfter: this.dates[0],
          rentalPeriodBefore: nextDay,
        };
      }
    },

    selectedStatus(newValue, oldValue) {
      if (isEqual(newValue, oldValue)) return;

      if (!newValue?.length) {
        this.statusModel = [...oldValue];
        return;
      }

      if (!oldValue.includes('booked') && newValue.includes('booked')) {
        this.statusModel = ['booked'];
        return;
      }

      if (newValue?.length > 1 && oldValue.includes('booked')) {
        this.statusModel = this.statusModel.filter(status => status !== 'booked');
        return;
      }

      this.statusModel = [...newValue];
    },
  },

  mounted() {
    timeService.subscribe(this.setDate);
  },

  beforeDestroy() {
    timeService.unsubscribe(this.setDate);
  },

  methods: {
    formatDate(date) {
      const noFormatDate = new Date(date);

      return format(noFormatDate, 'd L yyyy');
    },

    titleDateFormat(date) {
      return date.map(localDate => localDate && translateDate(this.formatDate(localDate))).join(' - ');
    },

    setDate(date) {
      this.currentDate = date;
    },
  },
};
</script>

<style lang="scss">
.form-field-date {
  // min-width: 290px !important;

  .v-input__append-inner {
    width: 28px;
    height: 24px;
  }
}

.calendar-status.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
