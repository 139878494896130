<template>
  <div class="form-field-month-date d-flex align-center justify-end">
    <div class="calendar-button d-flex align-center ps-4 pe-2">
      <v-menu
        ref="menu"
        v-model="menuIsOpen"
        :close-on-content-click="false"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <text-field
            :value="translatedModel"
            solo
            elevation="0"
            prepend-icon="mdi-calendar"
            readonly
            dense
            flat
            hide-details
            :class="{ 'menu-open': menuIsOpen }"
            v-bind="attrs"
            v-on="on"
          ></text-field>
        </template>
        <v-date-picker v-model="dateModel" type="month" no-title :locale="$i18n.locale" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menuIsOpen = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(dateModel)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-divider vertical class="ms-5 me-2" />

      <v-btn icon @click="setPrevMonth">
        <v-icon large>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn icon @click="setNextMonth">
        <v-icon large>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <v-btn elevation="0" color="#EEEEEE" large class="ms-6" @click="resetDate">
      {{ $t('button.today') }}
    </v-btn>
  </div>
</template>
<script>
// node_modules
import format from 'date-fns/format';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';

// Utils
import { getInheritedListeners } from '@/utils/components';
import { translateShortDate } from '@/utils/dateFormatting';

// Components
import TextField from '@/components/TextField.vue';

// Services
import timeService from '@/services/time';

export default {
  name: 'SchemaFormFieldMonthDate',

  components: { TextField },

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      menuIsOpen: false,
      date: this.value,
      currentDate: new Date(),
    };
  },

  computed: {
    modelWrapper: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },

    dateModel: {
      get() {
        return this.date || format(this.currentDate, 'yyyy-LL');
      },

      set(value) {
        this.date = value;
        this.modelWrapper = value;
      },
    },

    translatedModel() {
      return this.dateModel ? translateShortDate(this.formatDate(this.dateModel)) : '';
    },

    inheritedListeners: getInheritedListeners(['input']),
  },

  watch: {
    value(newDate) {
      if (!newDate) {
        this.date = '';
      } else {
        this.date = this.value;
      }
    },
  },

  mounted() {
    timeService.subscribe(this.setDate);
  },

  beforeDestroy() {
    timeService.unsubscribe(this.setDate);
  },

  methods: {
    formatDate(date) {
      const noFormatDate = new Date(date);

      return format(noFormatDate, 'L-yyyy');
    },

    titleDateFormat(date) {
      return translateShortDate(this.formatDate(date));
    },

    setPrevMonth() {
      this.dateModel = format(subMonths(new Date(this.dateModel), 1), 'yyyy-L');
    },

    setNextMonth() {
      this.dateModel = format(addMonths(new Date(this.dateModel), 1), 'yyyy-L');
    },

    resetDate() {
      this.dateModel = format(new Date(), 'yyyy-L');
    },

    setDate(date) {
      this.currentDate = date;
    },

    clear() {
      this.modelWrapper = '';
    },
  },
};
</script>

<style lang="scss">
.calendar-button {
  max-width: 245px;
  border-radius: 4px;
  border: 1px solid #e6ebff;
  height: 44px;

  .v-input {
    min-width: 118px;
  }

  .v-input__slot {
    padding: 0 !important;
  }

  .menu-open {
    .v-icon,
    input {
      color: #002fff;
    }
  }
}
</style>
