<template>
  <form-multi-select
    ref="multiselect"
    v-model="modelWrapper"
    :label="label"
    :error-messages="errors"
    :query-items="queryItems"
    item-text="name"
    :can-select-all="canSelectAll"
    :all-message="$t('label.all_projects')"
    :item-value="item => item"
    :value-comparator="$options.compareById"
    :is-filtered="isFiltered"
    v-on="inheritedListeners"
  />
</template>

<script>
// Utils
import { isEqual } from '@/utils/common';
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Services
import { fetchManyProjects, fetchCalendarManyProjects } from '@/services/select';

// Components
import FormMultiSelect from './FormMultiselect.vue';

export default {
  name: 'SchemaFormFieldManyProjects',

  components: { FormMultiSelect },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
    isCalendar: {
      type: Boolean,
      default: false,
    },
    urlStart: {
      type: String,
      default: 'realty',
    },
  },

  data() {
    return {
      canSelectAll: false,
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),

    isFiltered() {
      const filteredByProjects = this.payload.projects
        ? !this.payload.projects.all || !!this.payload.projects.include.length
        : false;

      const filteredByClients = this.payload.clients
        ? !this.payload.clients.all || !!this.payload.clients.include.length
        : false;

      return filteredByProjects || filteredByClients;
    },
  },

  watch: {
    payload(newPayload, oldPayload) {
      if (!isEqual(newPayload, oldPayload)) {
        this.$refs.multiselect?.debouncedFetchItems();
      }
    },
  },

  methods: {
    queryItems(payload, config) {
      const { clients, newLimit } = this.payload;

      const fetch = this.isCalendar ? fetchCalendarManyProjects : fetchManyProjects;

      return fetch({ limit: newLimit || 100, ...payload, ...this.payload, clients }, config, this.urlStart).then(
        ({ count, results }) => {
          this.canSelectAll = !!results.length;
          return { count, results };
        }
      );
    },
  },

  compareById,
};
</script>
