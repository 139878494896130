<template>
  <div class="credentials-control pb-2 px-2">
    <p class="mb-0">{{ $t(label) }}</p>
    <div class="credentials-control__right-container">
      <v-checkbox
        v-model="credentialsReadingModel"
        :disabled="disabled"
        dense
        hide-details
        :label="$t('credentials_type.reading')"
      ></v-checkbox>
      <v-checkbox
        v-if="withCredentials"
        v-model="credentialsWritingModel"
        :disabled="disabled"
        dense
        hide-details
        :label="$t('credentials_type.writing')"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import { ACTIONS } from '@/constants/credentialActionTypes';

// const actionList = [ACTIONS.READ, ACTIONS.WRITE];

export default {
  name: 'FormFieldCredentialsControl',
  props: {
    withCredentials: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    allAccess: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      credentials: [],
    };
  },
  computed: {
    // credentialsModel: {
    //   get() {
    //     return this.credentials.some(credential => actionList.includes(credential));
    //   },
    //   set(value) {
    //     if (value) {
    //       this.selectAllCredentials();
    //     } else {
    //       this.deleteAllCredentials();
    //     }
    //     this.emitCredentialsChange();
    //   },
    // },
    credentialsReadingModel: {
      get() {
        return this.credentials.includes(ACTIONS.READ);
      },
      set(value) {
        if (value) {
          if (!this.withCredentials) {
            this.credentials.push(ACTIONS.WRITE);
          }
          this.credentials.push(ACTIONS.READ);
        } else {
          this.deleteAllCredentials();
        }
        this.emitCredentialsChange();
      },
    },
    credentialsWritingModel: {
      get() {
        return this.credentials.includes(ACTIONS.WRITE);
      },
      set(value) {
        if (value) {
          this.selectAllCredentials();
        } else {
          this.credentials = this.credentials.filter(type => type !== ACTIONS.WRITE);
        }
        this.emitCredentialsChange();
      },
    },
  },
  watch: {
    allAccess(newValue) {
      if (this.disabled) {
        return;
      }
      this.credentialsModel = newValue;
    },
    value: {
      immediate: true,
      handler(value) {
        this.credentials = value ? [...value] : [];
      },
    },
  },
  methods: {
    selectAllCredentials() {
      this.credentials = [ACTIONS.READ, ACTIONS.WRITE];
    },
    deleteAllCredentials() {
      this.credentials = [];
    },
    emitCredentialsChange() {
      this.$emit('change', { name: this.name, credentials: this.credentials });
    },
  },
};
</script>

<style lang="scss">
.credentials-control {
  margin-top: 24px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 196, 0.15);
  &:first-of-type {
    margin-top: 12px;
  }
  .v-input--selection-controls {
    margin: 0;
  }
  &__right-container {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .v-input {
      margin-left: 19px;
    }
    @media (max-width: 500px) {
      display: block;
    }
  }
}
</style>
