import { FORM } from '@/constants/builder';
import { HIDDEN } from '@/constants/value';
import { ENUM } from '@/constants/viewTypes';
import * as comparators from '@/constants/comparators';
import * as serializers from '@/constants/serializer';

import * as schemaItemTypes from './schemaItemTypes';
import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';
import * as onUpdateEffects from './onUpdateEffects';

const dependentProperties = {
  BUILDINGS: 'buildings',
  PROJECTS: 'projects',
  UNITS: 'units',
  ROOMS: 'rooms',
  ROLES_PENDING: 'rolesPending',
  ROLES_IN_PROGRESS: 'rolesInProgress',
  ROLES_COMPLETES: 'rolesCompleted',
  PAYMENT_TYPE: 'paymentTypes',
  TYPE: 'type',
  CATEGORY: 'category',
};

export const schemaType = [
  {
    type: schemaItemTypes.STRING,
    label: 'label.title',
    prop: 'name',
    rules: [rules.REQUIRED],
  },
];

export const schemaCategory = [
  {
    type: schemaItemTypes.REQUEST_TYPE,
    label: 'label.type',
    prop: 'categoryType',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.STRING,
    label: 'label.title',
    prop: 'title',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.REQUEST_ICON,
    label: 'label.cover_icon',
    prop: 'icon',
    rules: [rules.REQUIRED],
  },
];

export const schemaBaseInfo = [
  {
    type: schemaItemTypes.REQUEST_TYPE,
    label: 'label.type',
    prop: dependentProperties.TYPE,
    rules: [rules.REQUIRED],
    onUpdate: [
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.CATEGORY,
      },
    ],
  },
  {
    type: schemaItemTypes.REQUEST_CATEGORY,
    label: 'label.category',
    prop: dependentProperties.CATEGORY,
    rules: [rules.REQUIRED],
    payload: [
      {
        param: 'categoryType',
        from: [dependentProperties.TYPE, 'id'],
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.SET,
        to: dependentProperties.TYPE,
        from: ['categoryType'],
      },
    ],
  },
  {
    type: schemaItemTypes.STRING,
    label: 'label.title',
    prop: 'title',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.STRING,
    label: 'label.description',
    prop: 'description',
  },
  {
    type: schemaItemTypes.REQUEST_ICON,
    label: 'label.cover_icon',
    prop: 'icon',
    rules: [rules.REQUIRED],
  },
];

export const schemaRequestForm = [
  {
    type: schemaItemTypes.REQUEST_FIELDS_LIST,
    prop: 'fields',
    uniqueKey: 'id',
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'label.name',
        prop: 'name',
        rules: [rules.REQUIRED],
      },
      {
        label: 'label.type',
        prop: 'fieldType',
        type: schemaItemTypes.ENUM,
        rules: [rules.REQUIRED],
        dictionary: dictionaryTypes.REQUEST_FIELDS_LIST,
      },
      {
        type: schemaItemTypes.COLUMN_LIST,
        prop: 'fieldOptions',
        uniqueKey: 'id',
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'label.column',
            prop: 'title',
            rules: [rules.REQUIRED],
          },
        ],
        displayConditions: {
          variables: [
            {
              from: ['fieldType', 'value'],
              comparableValues: ['rowadder'],
              comparator: comparators.STRICT_COMPARE,
            },
          ],
          expression: 'value',
        },
      },
    ],
  },
];

export const schemaAddField = [
  {
    type: schemaItemTypes.ENUM,
    viewType: ENUM.switch,
    label: 'label.mandatory_field',
    prop: 'isRequired',
    defaultValue: false,
    removeLabelSuffix: true,
  },
  {
    type: schemaItemTypes.STRING,
    label: 'label.name',
    prop: 'name',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.REQUEST_FIELDS_LIST_ENUM,
    label: 'label.type',
    prop: 'fieldType',
    rules: [rules.REQUIRED],
    hideItems: [],
  },
  {
    type: schemaItemTypes.COLUMN_LIST,
    prop: 'columns',
    uniqueKey: 'id',
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'label.column',
        prop: 'title',
        rules: [rules.REQUIRED],
      },
    ],
    displayConditions: {
      variables: [
        {
          from: ['fieldType', 'value'],
          comparableValues: ['rowadder'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: 'value',
    },
  },
  {
    type: schemaItemTypes.ENUM,
    viewType: ENUM.switch,
    label: 'label.allow_multiselect',
    prop: 'isMultiply',
    defaultValue: false,
    removeLabelSuffix: true,
    displayConditions: {
      variables: [
        {
          from: ['fieldType', 'value'],
          comparableValues: ['dropdown'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: 'value',
    },
  },
  {
    type: schemaItemTypes.COLUMN_LIST,
    prop: 'options',
    uniqueKey: 'id',
    addButtonText: 'button.add_option',
    min: 2,
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'label.option',
        prop: 'title',
        rules: [rules.REQUIRED],
      },
    ],
    displayConditions: {
      variables: [
        {
          from: ['fieldType', 'value'],
          comparableValues: ['dropdown'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: 'value',
    },
  },
];

export const schemaDetails = [
  {
    label: 'label.project',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.MANY_PROJECTS,
            label: 'label.projects',
            prop: dependentProperties.PROJECTS,
            rules: [rules.MANY_REQUIRED],
            urlStart: 'requests',
            payload: [
              {
                param: 'buildings',
                from: [dependentProperties.BUILDINGS],
                serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
                onlyEffect: true,
              },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.RESET,
                to: dependentProperties.BUILDINGS,
              },
              {
                type: onUpdateEffects.RESET,
                to: 'roles',
              },
            ],
          },
          {
            type: schemaItemTypes.MANY_BUILDINGS,
            label: 'label.buildings',
            prop: dependentProperties.BUILDINGS,
            urlStart: 'requests',
            payload: [
              {
                param: 'projects',
                from: [dependentProperties.PROJECTS],
                cache: true,
                serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
              },
              {
                param: 'units',
                from: [dependentProperties.UNITS],
                serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
                onlyEffect: true,
              },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.AUTOCOMPLETE_MANY,
                to: dependentProperties.PROJECTS,
              },
              {
                type: onUpdateEffects.RESET,
                to: dependentProperties.UNITS,
              },
            ],
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                type: schemaItemTypes.MANY_UNITS,
                label: 'label.units',
                prop: dependentProperties.UNITS,
                urlStart: 'requests',
                payload: [
                  {
                    param: 'projects',
                    from: [dependentProperties.PROJECTS],
                    cache: true,
                    serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
                  },
                  {
                    param: 'buildings',
                    from: [dependentProperties.BUILDINGS],
                    cache: true,
                    serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
                  },
                  {
                    param: 'rooms',
                    from: [dependentProperties.ROOMS],
                    serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
                    onlyEffect: true,
                  },
                ],
                onUpdate: [
                  {
                    type: onUpdateEffects.AUTOCOMPLETE_MANY,
                    to: dependentProperties.BUILDINGS,
                  },
                  {
                    type: onUpdateEffects.RESET,
                    to: dependentProperties.ROOMS,
                  },
                ],
              },
              {
                type: schemaItemTypes.MANY_ROOMS,
                label: 'label.rooms',
                prop: dependentProperties.ROOMS,
                access: ['rooms'],
                urlStart: 'requests',
                payload: [
                  {
                    param: 'projects',
                    from: [dependentProperties.PROJECTS],
                    cache: true,
                    serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
                  },
                  {
                    param: 'buildings',
                    from: [dependentProperties.BUILDINGS],
                    cache: true,
                    serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
                  },
                  {
                    param: 'units',
                    from: [dependentProperties.UNITS],
                    cache: true,
                    serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
                  },
                ],
                onUpdate: [
                  {
                    type: onUpdateEffects.AUTOCOMPLETE_MANY,
                    to: dependentProperties.UNITS,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: 'request.team',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ALERT,
        content: 'request.alert_role_description',
      },
      {
        type: schemaItemTypes.PROJECT_TEAM_FOR_TASK,
        label: 'label.roles',
        prop: 'roles',
        rules: [rules.REQUIRED],
        payload: [
          {
            param: 'projects',
            from: [dependentProperties.PROJECTS],
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
        ],
      },
    ],
  },
  {
    label: 'label.clients_type',
    type: schemaItemTypes.TITLED_SECTION,
    showRequiredSuffix: true,
    rules: [rules.REQUIRED],
    children: [
      {
        label: 'label.clients_type',
        type: schemaItemTypes.MANY_ENUM,
        dictionary: dictionaryTypes.CLIENT_TYPES,
        prop: 'clientType',
        rules: [rules.REQUIRED],
      },
    ],
  },
  {
    label: 'label.deadline',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.NUMBER,
        label: 'label.deadline_full_label',
        prop: 'deadline',
        defaultValue: null,
        rules: [rules.POSITIVE, rules.INTEGER],
      },
    ],
  },
  {
    label: 'label.payments',
    type: schemaItemTypes.TITLED_SECTION,
    rules: [rules.REQUIRED],
    visibility: [{ builderType: FORM, value: HIDDEN }],
    children: [
      {
        type: schemaItemTypes.ENUM,
        viewType: ENUM.radio,
        dictionary: dictionaryTypes.PAYMENT_TYPES,
        prop: 'paymentRequired',
        defaultValue: false,
      },
      {
        type: schemaItemTypes.NUMBER,
        label: 'label.amount',
        prop: 'paymentAmount',
        rules: [rules.REQUIRED],
        appendText: 'invoice.euro',
        displayConditions: {
          variables: [
            {
              from: ['paymentRequired'],
              comparableValues: [true],
              comparator: comparators.STRICT_COMPARE,
            },
          ],
          expression: `${'paymentRequired'}`,
        },
      },
      {
        type: schemaItemTypes.CONTRACTOR,
        label: 'label.contractor',
        prop: 'contractor',
        rules: [rules.REQUIRED],
        displayConditions: {
          variables: [
            {
              from: ['paymentRequired'],
              comparableValues: [true],
              comparator: comparators.STRICT_COMPARE,
            },
          ],
          expression: `${'paymentRequired'}`,
        },
      },
    ],
  },
];

export const shemaTerms = [
  {
    label: 'requests.link_to_terms',
    type: schemaItemTypes.STRING,
    prop: 'ticketTermsUrl',
    rules: [rules.URL],
  },
];
