<template>
  <div class="d-flex flex-row justify-space-between mb-5">
    <span v-if="firstPaymentDeadline" class="text-h5 text--secondary"> {{ label }}: </span>

    <span class="text-h5 font-weight-medium flex-shrink-0">{{ firstPaymentDeadline }}</span>
  </div>
</template>

<script>
import { addDays, format } from 'date-fns';

export default {
  name: 'SchemaFormFirstPaymentDeadline',

  props: {
    label: { type: String, required: true },
    payload: { type: Object, required: true },
  },

  computed: {
    firstPaymentDeadline() {
      if (!this.payload.firstPaymentDate) return null;
      const date = new Date(this.payload.firstPaymentDate);
      return format(addDays(date, this.payload.numberDaysDueDate || 0), 'dd.LL.yyyy');
    },
  },
};
</script>
