import {
  PERIOD,
  IS_TENANT,
  IS_TODAY,
  NEXT_DAY,
  EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
  EXTRACT_PARAMS_FROM_SELECT_OBJECT,
  NOT_AVAILABLE,
  IS_RENTED,
  CAPITALIZE_FIRST_LETTER,
} from '@/constants/serializer';

import {
  periodSerializer,
  isTenantSerializer,
  isTodaySerializer,
  nextDaySerializer,
  extractParamsFromMultiSelectObjectSerializer,
  extractParamsFromSelectObjectSerializer,
  notAvailable,
  isRented,
  capitalizeFirstLetterSerializer,
} from '@/services/serializations';

export default {
  [PERIOD]: periodSerializer,
  [IS_TENANT]: isTenantSerializer,
  [IS_TODAY]: isTodaySerializer,
  [NEXT_DAY]: nextDaySerializer,
  [EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT]: extractParamsFromMultiSelectObjectSerializer,
  [EXTRACT_PARAMS_FROM_SELECT_OBJECT]: extractParamsFromSelectObjectSerializer,
  [NOT_AVAILABLE]: notAvailable,
  [IS_RENTED]: isRented,
  [CAPITALIZE_FIRST_LETTER]: capitalizeFirstLetterSerializer,
};
