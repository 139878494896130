<template>
  <div class="mb-8 mt-2">
    <div class="mb-2">
      <span class="text-caption text--secondary"> {{ label }}: </span>
    </div>

    <div class="bedrooms" :class="{ disabled: unitType !== 'apartment' }">
      <label v-for="bedroom in bedroomsList" :key="bedroom.value" class="bedrooms-input">
        <input v-show="false" v-model="selectBedrooms" :value="bedroom.value" type="checkbox" />
        <div class="bedrooms-input__label">{{ bedroom.label }}</div>
      </label>
    </div>

    <div class="d-flex flex-wrap mt-2 mb-4">
      <v-checkbox
        v-for="type in unitTypeList"
        :key="type.value"
        v-model="unitType"
        :label="$t(type.label)"
        :value="type.value"
        hide-details
        class="mt-0 me-2"
      />
    </div>
  </div>
</template>

<script>
// Utils
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

export default {
  name: 'SchemaFormFieldUnitType',

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      bedroomsList: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
        {
          label: '5+',
          value: 5,
        },
      ],
      unitTypeList: [
        {
          label: 'label.unit_type_studio',
          value: 'studio',
        },
        {
          label: 'label.unit_type_room',
          value: 'room',
        },
        {
          label: 'label.unit_type_unit',
          value: 'apartment',
        },
      ],
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
    selectBedrooms: {
      get() {
        return this.value?.bedrooms ? [...this.value.bedrooms] : [];
      },

      set(value) {
        this.modelWrapper = {
          ...this.modelWrapper,
          bedrooms: value,
        };
      },
    },
    unitType: {
      get() {
        return this.value?.type || null;
      },

      set(value) {
        this.modelWrapper = {
          ...this.modelWrapper,
          type: value,
        };
      },
    },
  },

  methods: {
    clearSelect(type) {
      this.unitType = this.unitType === type ? null : type;
    },
  },
};
</script>

<style lang="scss">
.bedrooms {
  display: flex;
  column-gap: 8px;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.bedrooms-input {
  &__label {
    width: 32px;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    transition: color 0.25s, background-color 0.25s, border-color 0.25s;
  }

  input:checked + .bedrooms-input__label {
    border-color: $--blue-color-10;
    background: $--blue-color-55;
    color: $--blue-color-10;
  }
}
</style>
