<template>
  <form-multi-select
    ref="multiselect"
    v-model="modelWrapper"
    :label="label"
    :errors="errors"
    :query-items="queryItems"
    item-text="name"
    :can-select-all="canSelectAll"
    :all-message="$t('label.all_rooms')"
    :item-value="item => item"
    :value-comparator="$options.compareById"
    :is-filtered="isFiltered"
    v-on="inheritedListeners"
  />
</template>

<script>
// Utils
import { isEqual } from '@/utils/common';
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Services
import { fetchManyRooms } from '@/services/select';

// Components
import FormMultiSelect from './FormMultiselect.vue';

export default {
  name: 'SchemaFormFieldManyRooms',

  components: { FormMultiSelect },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
    urlStart: {
      type: String,
      default: 'realty',
    },
  },

  data() {
    return {
      canSelectAll: false,
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),

    isFiltered() {
      const filteredByProjects = this.payload.projects
        ? !this.payload.projects.all || !!this.payload.projects.include.length
        : false;
      const filteredByBuildings = this.payload.buildings
        ? !this.payload.buildings.all || !!this.payload.buildings.include.length
        : false;
      const filteredByUnits = this.payload.units
        ? !this.payload.units.all || !!this.payload.units.include.length
        : false;
      const filteredByClients = this.payload.clients
        ? !this.payload.clients.all || !!this.payload.clients.include.length
        : false;

      return filteredByProjects || filteredByBuildings || filteredByUnits || filteredByClients;
    },
  },

  watch: {
    payload(newPayload, oldPayload) {
      if (!isEqual(newPayload, oldPayload)) {
        this.$refs.multiselect?.debouncedFetchItems();
      }
    },
  },

  methods: {
    queryItems(payload, config) {
      const { projects, buildings, units, clients, newLimit } = this.payload;

      return fetchManyRooms(
        {
          limit: newLimit || 100,
          ...payload,
          ...this.payload,
          projects,
          buildings,
          units,
          clients,
        },
        config,
        this.urlStart
      ).then(({ count, results }) => {
        this.canSelectAll = !!results.length;
        return { count, results };
      });
    },
  },

  compareById,
};
</script>
