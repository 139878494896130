<template>
  <v-dialog v-model="dialog" persistent width="560">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <v-card content-class="base-modal__card">
      <v-card-title class="pb-8 px-4 pt-4 px-md-6 pt-md-6 align-start justify-space-between flex-nowrap">
        <h1 class="text-h6 text-md-h5 font-weight-bold">
          {{ title }}
        </h1>

        <v-btn icon color="primary" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-4 px-md-6">
        <form-builder v-if="dialog" :schema="schema" :initial-data="initialData" @submit="close">
          <template #footer="{ valid }">
            <slot name="footer" :valid="valid" />
          </template>
        </form-builder>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormModal',

  components: {
    FormBuilder: () => import('./FormBuilder.vue'),
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    schema: {
      type: Array,
      default: () => [],
    },
    initialData: {
      type: Object,
      default: () => ({}),
    },
    async: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialog: false,
      isLoading: false,
    };
  },

  methods: {
    close(evt) {
      if (this.async) {
        this.$emit('submit', [
          evt,
          () => {
            this.dialog = false;
          },
        ]);
        return;
      }
      this.$emit('submit', evt);
      setTimeout(() => {
        this.dialog = false;
      });
    },
  },
};
</script>
