export const PROJECT_TYPES = 'project-types';
export const PROPERTY_TYPES = 'property-types';
export const PARKING_TYPES = 'parking-types';
export const PERIOD_TYPES = 'period-types';
export const CLIENT_TYPES = 'client-types';
export const PATMENT_DEADLINE_TYPES = 'payment-deadline-types';
export const INVOICE_STATUS = 'invoice-status';
export const COUNTRIES = 'countries';
export const BUILDINGS = 'buildings';
export const PROJECTS = 'projects';
export const UNITS = 'units';
export const TIMES = 'times';
export const APPROVAL = 'approval';
export const PAYMENT_TYPES = 'paymentTypes';
export const AREA_UNIT = 'areaUnit';
export const RECIPIENTS = 'recipients';
export const INTERVAL_DAYS = 'interval_days';
export const NOTIFICATION = 'notification';
export const CLIENTS = 'clients';
export const SERVICE_TYPES = 'serviceTypes';
export const RECURRENCE_PERIODS = 'recurrencePeriods';
export const UNITY_STATUSES = 'unityStatuses';
export const ROOM_STATUSES = 'roomStatuses';
export const UNITS_MEASUREMENT = 'unitsMeasurement';
export const ACCOUNTING = 'accounting';
export const SERVICES_SCENARIO_TYPES = 'servicesScenarioTypes';
export const REQUEST_FIELDS_LIST = 'requestFieldsList';
export const TABLE_COLUMNS = 'tableColumns';
