<template>
  <div class="form-credentials">
    <div class="form-credentials__title mb-10">
      <h4 class="form-credentials__title-text">{{ label }}</h4>
    </div>

    <div class="mt-5 mb-8">
      <v-checkbox
        v-model="isAllSelected"
        class="form-credentials__checkbox"
        :class="indeterminateClass"
        :off-icon="iconOffAllSelected"
        dense
      >
        <template #label>
          <p class="text-h6 mb-0 text--primary">{{ $t('employee.credentials-all-access') }}</p>
        </template>
      </v-checkbox>
    </div>
    <div v-for="(section, index) in credentialsVariants" :key="index" class="mb-12">
      <p class="text-h5 font-weight-medium mb-6 text--primary">{{ $t(section.label) }}</p>
      <form-field-credentials-control
        v-for="variant in section.credentials"
        :key="variant.id"
        :name="variant.name"
        :label="variant.label"
        :with-credentials="variant.withCredentials"
        :value="variant.value"
        :disabled="variant.disabled"
        @change="changeCredentialsControl"
      />
    </div>
  </div>
</template>

<script>
import { getCredentialsObject } from '@/schemas/createModelData';
import { ACTIONS } from '@/constants/credentialActionTypes';
import FormFieldCredentialsControl from './FormFieldCredentialsControl.vue';

const credentialsConfig = [
  {
    label: 'employee.credentials_section_analytics',
    credentials: {
      dashboard: { label: 'navigation.dashboard', withCredentials: false },
      contractors: { label: 'navigation.contractors', withCredentials: false },
    },
  },
  {
    label: 'employee.credentials_section_managmentCompany',
    credentials: {
      employees: { label: 'navigation.employees', withCredentials: true },
      projects: { label: 'navigation.projects', withCredentials: true },
      clients: { label: 'navigation.clients', withCredentials: true },
    },
  },
  {
    label: 'employee.credentials_section_finance',
    credentials: {
      invoices: { label: 'navigation.invoice', withCredentials: true },
      contracts: { label: 'navigation.contracts', withCredentials: true },
    },
  },
  {
    label: 'employee.credentials_section_clientRelations',
    credentials: {
      calendar: { label: 'navigation.calendar', withCredentials: false },
      requests: { label: 'navigation.requests', withCredentials: true },
      issues: { label: 'navigation.task', withCredentials: true },
      services: { label: 'navigation.services', withCredentials: true },
      announcements: { label: 'navigation.announcements', withCredentials: true },
      chat: { label: 'navigation.chat', withCredentials: false },
    },
  },
];

// максимально возможное количество доступов
const MAX_CREDENTIALS_COUNT = 26;

export default {
  name: 'FormFieldCredentials',
  components: { FormFieldCredentialsControl },
  props: {
    value: {
      type: Object,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    isAllSelected: {
      get() {
        return this.credentialsCount >= MAX_CREDENTIALS_COUNT;
      },
      set(value) {
        const credentials = value ? this.getAllCredentialsObject() : this.getEmptyCredentialsObject();
        this.changeCredentials(credentials);
      },
    },

    iconOffAllSelected() {
      if (this.credentialsCount > 0) {
        return 'mdi-minus-box';
      }
      return '$checkboxOff';
    },
    indeterminateClass() {
      return {
        'form-credentials__checkbox--indeterminate': this.credentialsCount > 0,
      };
    },
    credentialsCount() {
      const allCredentials = Object.values(this.value).reduce((list, credentials) => {
        return [...list, ...credentials];
      }, []);

      return allCredentials.length;
    },

    credentialsVariants() {
      return credentialsConfig.map(section => {
        const updatedCredentials = Object.entries(section.credentials).map(([name, config]) => {
          const value = this.value[name];
          let disabled = false;
          if (this.payload.isSelf) {
            disabled = name === 'employees';
          }
          const item = { ...config, name, value, disabled };
          return item;
        });

        return { label: section.label, credentials: updatedCredentials };
      });
    },
    disabledNames() {
      return this.credentialsVariants.filter(item => item.disabled).map(item => item.name);
    },
  },

  methods: {
    changeCredentialsControl({ name, credentials }) {
      const newValue = { ...this.value, [name]: credentials };
      this.changeCredentials(newValue);
    },
    changeCredentials(value) {
      this.$emit('input', value);
    },
    getAllCredentialsObject() {
      return Object.keys(getCredentialsObject()).reduce((allCredentials, name) => {
        // eslint-disable-next-line no-param-reassign
        allCredentials[name] = [ACTIONS.READ, ACTIONS.WRITE].slice();
        if (this.disabledNames.includes(name)) {
          // eslint-disable-next-line no-param-reassign
          allCredentials[name] = this.credentialsVariants.find(item => item.name === name).value.slice();
        }
        // eslint-disable-next-line no-param-reassign
        allCredentials[name] = [ACTIONS.READ, ACTIONS.WRITE];
        return allCredentials;
      }, {});
    },
    getEmptyCredentialsObject() {
      const credentialsObject = getCredentialsObject();
      this.disabledNames.forEach(
        // eslint-disable-next-line no-return-assign
        item => (credentialsObject[item] = [...this.credentialsVariants.find(variant => variant.name === item).value])
      );
      return credentialsObject;
    },
  },
};
</script>

<style lang="scss">
.form-credentials {
  margin-bottom: 15px;
  &__title {
    display: flex;
    align-items: baseline;
    margin-bottom: -14px;
  }
  &__title-text {
    font-weight: $--font-weight-medium;
    font-size: 24px;
    line-height: 32px;
    margin-right: 19px;
  }
  &__checkbox {
    &--indeterminate {
      .v-input--selection-controls__input .v-icon {
        color: var(--v-primary-base);
      }
    }
  }
}
</style>
