<template>
  <div class="mb-8">
    <div class=" mb-2">
      <span class="text-caption text--secondary"> {{ $t(label) }}: </span>
    </div>
    <div class="d-flex mb-2">
      <v-text-field
        v-model="goalRentCostMin"
        :disabled="rentCostNotPresent"
        dense
        hide-details
        :label="$t('from')"
        outlined
      ></v-text-field>
      <span class="mx-2 mt-2">&mdash;</span>
      <v-text-field
        v-model="goalRentCostMax"
        :disabled="rentCostNotPresent"
        dense
        hide-details
        :label="$t('to')"
        outlined
      ></v-text-field>
    </div>
    <v-checkbox v-model="rentCostNotPresent" :label="$t('label.price_not_specified')" hide-details class="mt-0" />
  </div>
</template>

<script>
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

export default {
  name: 'SchemaFormAmountRange',

  props: {
    value: {
      type: Object,
      default: null,
    },
    label: { type: String, required: true },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
    goalRentCostMin: {
      get() {
        return this.value?.min;
      },
      set(value) {
        this.modelWrapper = {
          ...this.modelWrapper,
          min: value,
        };
      },
    },
    goalRentCostMax: {
      get() {
        return this.value?.max;
      },
      set(value) {
        this.modelWrapper = {
          ...this.modelWrapper,
          max: value,
        };
      },
    },
    rentCostNotPresent: {
      get() {
        return this.value?.rentCostNotPresent;
      },
      set(value) {
        this.modelWrapper = {
          ...this.modelWrapper,
          rentCostNotPresent: value,
        };
      },
    },
  },
};
</script>

<style lang="scss">
// .v-input__slot {
//   min-height: 30px !important;
//   input {
//     padding: 0 !important;
//   }
// }
</style>
