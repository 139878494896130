<template>
  <span>
    <v-alert :color="color" colored-border border="top" :type="alertType" elevation="2">
      {{ $t(content) }}
    </v-alert>
  </span>
</template>

<script>
export default {
  name: 'SchemaFormAlert',

  inheritAttrs: false,

  props: {
    alertType: {
      type: String,
      default: 'info',
    },

    color: {
      type: String,
      default: '#002fff',
    },

    content: {
      type: String,
      required: true,
    },
  },
};
</script>
