<template>
  <div class="form-field-file-list">
    <div v-if="label" class="form-field-file-list__label" :class="{ 'form-field-file-list__label--row': showRow }">
      {{ label }}
    </div>

    <div class="form-field-file-list__body" :class="{ 'd-flex': showRow }">
      <FormFileUpload
        v-if="editable && showDropzone"
        class="form-field-file-list__input"
        :class="{ 'form-field-file-list__input--row': showRow }"
        :accept="accept"
        :multiple="!onlyOne"
        :disabled="disableUpload"
        :show-row="showRow"
        @input="addNewFile"
      />
      <form-file-item
        v-for="({ tempId, url, privateUrl, isLoading, size, name, type, id, selected }, index) in modelWrapper"
        :key="tempId"
        :show-row="showRow"
        class="mr-3 mb-3"
        :url="url"
        :private-url="privateUrl"
        :loading="isLoading"
        :editable="editable"
        :size="size"
        :name="name"
        :type="type"
        :selected="selected"
        :selectable="selectable"
        :file-is-loaded="fileIsLoaded"
        @remove="removeItem(index)"
        @click="onItemClick(tempId || id)"
        @toggle-select="toggleSelect(tempId || id)"
      />
      <photo-slider
        v-if="!hiddenSlider && !selectable"
        ref="slider"
        v-model="currentSlide"
        :images="imagesForSlider"
        :title="title"
      />
    </div>
  </div>
</template>

<script>
// Utils
import { getUuid } from '@/utils/generators';

// Services
import mediaService from '@/services/media';

// Components
import PhotoSlider from '@/components/PhotoSlider.vue';
import FormFileItem from './FormFileItem.vue';
import FormFileUpload from './FormFileUpload.vue';

export default {
  name: 'SchemaFormFieldFileList',

  components: { FormFileItem, FormFileUpload, PhotoSlider },

  inheritAttrs: false,

  props: {
    value: { type: Array, default: () => [] },
    payload: { type: Object, default: () => ({}) },
    hiddenSlider: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    label: { type: String, default: '' },
    accept: { type: String, default: '*' },
    showRow: { type: Boolean, default: false },
    onlyOne: { type: Boolean, default: false },
    uploadType: { type: String, default: 'file' },
    uploadFile: { type: Boolean, default: true },
    fileIsLoaded: { type: Boolean, default: true },
  },

  data() {
    return {
      maxFileNumbers: 10,
      currentSlide: 0,
    };
  },

  computed: {
    modelWrapper: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
        // Тригерит vee-validate
        this.$emit('blur');
      },
    },

    title() {
      return this.payload?.listTitle || this.label;
    },

    disableUpload() {
      return this.modelWrapper.length >= 10;
    },

    titleForSlider() {
      return this.payload?.title || '';
    },

    images() {
      return this.value ? this.value.filter(file => this.fileIsImage(file.type)) : [];
    },
    imagesForSlider() {
      return this.value ? this.value.filter(file => this.fileIsImage(file.type)).map(image => image.url) : [];
    },
    showDropzone() {
      return this.editable && this.onlyOne ? this.modelWrapper.length < 1 : this.editable;
    },
  },

  methods: {
    onItemClick(id) {
      if (!this.hiddenSlider) {
        const indexImage = this.images.findIndex(img => img.id === id || img.tempId === id);
        this.openSlider(indexImage);
      }
    },

    toggleSelect(id) {
      this.modelWrapper = this.modelWrapper.map(item => {
        if (item.id === id || item.tempId === id) {
          return { ...item, selected: true };
        }
        return { ...item, selected: false };
      });
    },

    addNewFile(files) {
      if (!this.uploadFile) {
        this.$emit('input', files);
        return;
      }

      const filesArray = Array.from(files);

      filesArray.slice(0, Math.max(0, this.maxFileNumbers - this.modelWrapper.length)).forEach(file => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.addEventListener('load', async fileUrl => {
          const tempId = getUuid();

          const length = this.addItem({
            url: fileUrl.target.result,
            tempId,
            isLoading: true,
            type: file.type,
            size: file.size,
            name: file.name,
          });
          if (!length) return;

          const upload = this.uploadType === 'image' ? mediaService.uploadImage : mediaService.uploadFile;

          upload(file)
            .then(tempFileData => {
              const index = this.findFileIndex(tempId);
              if (index === -1) {
                return;
              }

              const originFilesList = this.modelWrapper.map(item => ({ ...item, selected: false }));

              const newFields = {
                backgroundFileId: tempFileData.id,
                isLoading: false,
                selected: true,
              };

              originFilesList.splice(index, 1, { ...originFilesList[index], ...newFields });

              this.modelWrapper = originFilesList;
            })
            .catch(() => {
              const index = this.findFileIndex(tempId);
              if (index === -1) {
                return;
              }
              this.removeItem(index);
            });
        });
      });
    },

    removeItem(index) {
      const newValue = this.modelWrapper.slice();
      newValue.splice(index, 1);
      this.modelWrapper = newValue;
    },

    fileIsImage(type) {
      return type ? type.startsWith('image/') : false;
    },

    addItem(item) {
      const modelWrapper = [...this.modelWrapper, item];
      this.modelWrapper = modelWrapper;
      return modelWrapper.length;
    },

    openSlider(index) {
      this.currentSlide = index;
      if (this.$refs?.slider.open) {
        this.$refs.slider.open();
      }
    },
    findFileIndex(tempId) {
      return this.modelWrapper.findIndex(item => item.tempId === tempId);
    },
  },
};
</script>

<style lang="scss">
.form-field-file-list {
  &__input {
    margin-bottom: 34px;
    display: block;
    cursor: pointer;
    &--row {
      order: 1;
    }
  }
  &__label {
    font-weight: $--font-weight-medium;
    font-size: 24px;
    line-height: 32px;
    color: $--black-color-0;
    margin-bottom: 24px;
    &--row {
      font-weight: $--font-weight-normal;
      font-size: 16px;
      line-height: 24px;
      color: $--grey-color-0;
      margin-bottom: 8px;
    }
  }
}
</style>
